const detailPageStyle = (theme) => ({
  detailPageContainer: {
    backgroundColor: theme.palette.background.elitGray,
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    border: '1px solid ' + theme.palette.border,
    padding: 40,
    boxSizing: 'border-box',
  },
  formTitle: {
    minHeight: 35.2,
    fontSize: 16,
    fontWeight: 600,
  },
  overflowAuto: {
    overflow: 'auto',
  },
  lastRowRoundBorder: {
    '& tbody': {
      '& tr:last-child': {
        '& td.MuiTableCell-body': {
          borderBottom: '1px solid ' + theme.palette.border,
          boxSizing: 'border-box',
          '&:first-child': {
            borderLeft: '1px solid ' + theme.palette.border,
            borderBottomLeftRadius: theme.shape.borderRadius,
          },
          '&:last-child': {
            borderBottomRightRadius: theme.shape.borderRadius,
            borderRight: '1px solid ' + theme.palette.border,
          },
        },
      },
    },
  },
  detailTable: {
    '& tbody tr td': {
      backgroundColor: theme.palette.background.white,
    },
    '& tbody tr:last-child': {
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  textCenter: {
    textAlign: 'center',
  },
  addButton: {
    width: 30,
    boxSizing: 'border-box',
    '& div, span': {
      height: 30,
      padding: 0,
    },
  },
  attachmentTablePadding: {
    '&:nth-child(1)': {
      paddingRight: 8,
    },
    '&:nth-child(2)': {
      paddingLeft: 8,
    },
  },
  downloadLink: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    cursor: 'pointer',
  },

  fleetMapContainer: {
    maxHeight: '100%',
    width: 'auto',
  },
  fleetDetail: {
    fontSize: 13,
    color: theme.palette.text.primary,
    marginBottom: 6,
    marginRight: 40,
    borderBottom: '1px solid ' + theme.palette.border,
  },
  fleetDetailLabel: {
    fontWeight: 700,
  },
  fleetDetailText: {},
  statusDescription: {
    height: 150,
    boxSizing: 'border-box',
    display: 'flex',
    alignContent: 'flex-start',
  },
  statusControl: {
    height: 150,
    boxSizing: 'border-box',
    display: 'flex',
    alignContent: 'flex-start',
  },
  tableHeaderGrid: {
    height: 32,
    fontSize: 12,
    boxSizing: 'border-box',
    fontWeight: 600,
    lineHeight: 1.1,
    paddingBottom: 2,
    color: theme.palette.text.secondary,
  },
  paddingBottomRow: {
    paddingBottom: 8,
  },
  addItemButton: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px dashed ' + theme.palette.border,
    boxSizing: 'border-box',
    borderRadius: theme.shape.borderRadius,
    margin: 4,
    color: theme.palette.text.disabled,
    fontSize: 12,
    fontWeight: 600,
    cursor: 'pointer',
    transition: 'color 200ms, border 200ms',
    height: 30,

    '&:hover': {
      color: theme.palette.text.secondary,
      border: '2px dashed ' + theme.palette.text.secondary,
      transition: 'color 200ms, border 200ms',
    },
  },
  removeItemButton: {
    margin: '0px 0px 0px 8px',
    padding: 0,
    border: '1px solid rgba(0, 0, 0, 0.26)',
    borderRadius: theme.shape.borderRadius,
    height: 30,
    width: 30,
    backgroundColor: theme.palette.background.white,

    '&:hover': {
      border: '1px solid ' + theme.palette.text.primary,
      backgroundColor: 'transparent',
    },
  },
  staticField: {
    backgroundColor: theme.palette.background.white,
    height: 30,
    padding: '4px 8px',
    fontSize: 12,
    boxSizing: 'border-box',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    letterSpacing: '0.00938em',
    boxShadow: '0 2px 6px 0 rgb(103 103 139 / 10%)',
    '&:hover': {
      border: '1px solid ' + theme.palette.text.primary,
    },
  },
  errorStaticField: {
    border: `1px solid ${theme.palette.error.main}`,
    '&:hover': {
      border: '1px solid ' + theme.palette.error.main,
    },
  },
  textAlignEnd: {
    justifyContent: 'flex-end',
    '& input': {
      textAlign: 'end',
    },
  },
  tooltipLabel: {
    borderBottom: '1px dotted gray',
    cursor: 'help',
  },
  statusDescriptionInChangeLogWrapper: {
    maxWidth: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    height: '100%',
    position: 'relative',
    top: 5,
  },
  statusDescriptionInChangeLog: {
    borderBottom: '1px dotted gray',
  },
  supplierCalendarIcon: {
    fill: '#BABDCC',
    opacity: 0.8,
    cursor: 'pointer',
    '&:hover': {
      opacity: 1,
      transition: 'fill 200ms',
    },
  },
  documentIcon: {
    '& svg': {
      fill: theme.palette.primary.main + ' !important',
      width: 17,
      height: 17,
    },
  },
  lightBackgroundColor: {
    backgroundColor: theme.palette.background.elitDarkGray,
  },
})

export default detailPageStyle
