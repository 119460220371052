import detailPageStyle from 'component/detailPageStyle'
import CheckBoxInput from 'component/field/CheckBoxInput'
import SelectInput from 'component/field/SelectInput'
import TextInput from 'component/field/TextInput'
import BoxFullWidth from 'component/material/BoxFullWidth'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import PrimaryButton from 'component/material/PrimaryButton'
import {isLoggedUser, mapErrorResponseToForm, redirectTo} from 'helper/functions'
import {validatePower, validateVehicleCC, validateVin} from 'helper/validations'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {Field, Form} from 'react-final-form'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'
import {bindActionCreators, compose} from 'redux'
import {createCar, getCar, patchCar} from 'redux/action/carsAction'
import {getFleetList} from 'redux/action/fleetsAction'
import {getMarks} from 'redux/action/suppliersAction'
import {Trans} from '@lingui/macro'
import Box from '@material-ui/core/Box'
import withStyles from '@material-ui/core/styles/withStyles'

const CarDetail = (props) => {
  const {
    classes,
    getCar,
    car,
    patchCar,
    createCar,
    getFleetList,
    fleetList,
    fleetListLoading,
    getMarks,
    marksLoading,
    marks,
  } = props

  let {id} = useParams()

  const prepareValues = (values) => {
    return {
      ...values,
    }
  }

  const handleSubmit = (values) => {
    const preparedValues = prepareValues(values)
    if (id === 'new') {
      return createCar(preparedValues)
        .then((res) => {
          redirectTo('/car')
        })
        .catch((err) => {
          return mapErrorResponseToForm(err)
        })
    } else {
      return patchCar(id, preparedValues)
        .then((res) => {
          redirectTo('/car')
        })
        .catch((err) => {
          return mapErrorResponseToForm(err)
        })
    }
  }

  const getInitialValues = () => {
    return {
      ...car,
    }
  }

  useEffect(() => {
    if (isLoggedUser()) {
      if (id !== 'new') {
        getCar(id).catch(() => {})
      }
      getFleetList().catch(() => {})
      getMarks().catch(() => {})
    } else {
      redirectTo('/')
    }
  }, [])

  return (
    <div className={classes.detailPageContainer}>
      <Form
        onSubmit={handleSubmit}
        initialValues={id !== 'new' ? getInitialValues() : {enabled: true}}
      >
        {(formProps) => {
          return (
            <form onSubmit={formProps.handleSubmit}>
              <GridContainer direction="row" justifyContent="flex-start" alignItems="flex-start">
                <GridContainer item xs={5}>
                  <BoxFullWidth p={2} className={classes.formTitle}>
                    <Trans>General information</Trans>
                  </BoxFullWidth>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="fleet_id"
                      label={<Trans>Fleet</Trans>}
                      component={SelectInput}
                      options={fleetList?.map((fleet) => ({
                        name: fleet.customer_code ? fleet.customer_code : fleet.customer,
                        value: fleet.id,
                      }))}
                      loading={fleetListLoading}
                      emptyOption={true}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="mark_id"
                      label={<Trans>Mark</Trans>}
                      component={SelectInput}
                      options={marks?.map((m) => ({
                        name: m.name,
                        value: m.id,
                      }))}
                      loading={marksLoading}
                      emptyOption={true}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="model_desc"
                      label={<Trans>Model description</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="year"
                      label={<Trans>Year</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="power"
                      label={<Trans>Power</Trans>}
                      component={TextInput}
                      inputJustifyRight={true}
                      type={'number'}
                      inputProps={{min: 0, max: 1000, step: 1}}
                      helperText={
                        <Trans>Power must be between 10 and 1000, or 0 if not applicable</Trans>
                      }
                      validate={validatePower}
                      onFocus={(e) => e.target.select()}
                      onKeyDown={(e) => {
                        if (e.key === 'ArrowUp' && e.target.value < 10 && e.target.value >= 0) {
                          formProps.form.change('power', 10)
                          e.preventDefault()
                        }
                        if (e.key === 'ArrowDown' && e.target.value <= 10 && e.target.value > 0) {
                          formProps.form.change('power', 0)
                          e.preventDefault()
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="vehicle_cc"
                      label={<Trans>Vehicle cc</Trans>}
                      component={TextInput}
                      inputJustifyRight={true}
                      type={'number'}
                      inputProps={{min: 0, max: 9999, step: 1}}
                      helperText={
                        <Trans>
                          Vehicle cc must be between 500 and 9999, or 0 if not applicable
                        </Trans>
                      }
                      validate={validateVehicleCC}
                      onFocus={(e) => e.target.select()}
                      onKeyDown={(e) => {
                        if (e.key === 'ArrowUp' && e.target.value < 500 && e.target.value >= 0) {
                          formProps.form.change('vehicle_cc', 500)
                          e.preventDefault()
                        }
                        if (e.key === 'ArrowDown' && e.target.value <= 500 && e.target.value > 0) {
                          formProps.form.change('vehicle_cc', 0)
                          e.preventDefault()
                        }
                      }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  item
                  xs={1}
                />

                <GridContainer
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  item
                  xs={5}
                >
                  <BoxFullWidth p={2} className={classes.formTitle}></BoxFullWidth>

                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="fuel"
                      label={<Trans>Fuel</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="rz"
                      label={<Trans>RZ</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="vin"
                      label={<Trans>VIN</Trans>}
                      component={TextInput}
                      validate={validateVin}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="product_code"
                      label={<Trans>Product code</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="account_name"
                      label={<Trans>Account name</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="enabled"
                      label={<Trans>Enabled</Trans>}
                      component={CheckBoxInput}
                      type={'checkbox'}
                    />
                  </GridItem>
                </GridContainer>
              </GridContainer>
              <GridItem xs={12} container justifyContent="flex-end">
                <Box pt={5}>
                  <PrimaryButton
                    fullWidth={false}
                    disabled={formProps.submitting}
                    size="small"
                    type="submit"
                    textPadding={5}
                    text={<Trans>Save</Trans>}
                  />
                </Box>
              </GridItem>
            </form>
          )
        }}
      </Form>
    </div>
  )
}

CarDetail.propTypes = {
  classes: PropTypes.object,
  getCar: PropTypes.func,
  patchCar: PropTypes.func,
  getMarks: PropTypes.func,
  createCar: PropTypes.func,
  car: PropTypes.object,
  carLoading: PropTypes.bool,
  getFleetList: PropTypes.func,
  fleetList: PropTypes.array,
  fleetListLoading: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCar,
      patchCar,
      getMarks,
      createCar,
      getFleetList,
    },
    dispatch
  )
}

export default compose(
  withStyles(detailPageStyle),
  connect((store) => {
    return {
      car: store.car.car,
      carLoading: store.car.carLoading,
      fleetList: store.fleet.fleetList,
      fleetListLoading: store.fleet.fleetListLoading,
      marksLoading: store.supplier.marksLoading,
      marks: store.supplier.marks,
    }
  }, mapDispatchToProps)
)(CarDetail)
